@charset 'UTF-8';
@import './setting/variables';

.C0065 {
	position:relative;
	&.align-left {
		.item {
			.model-name,
			.body-copy {
				text-align:left;
			}
		}
	}
	&.align-right {
		.item {
			.model-name,
			.body-copy {
				text-align:right;
			}
		}
	}
	&.align-center {
		.item {
			.model-name,
			.body-copy {
				text-align:center;
			}
		}
	}
	.slick-track {margin:0 auto;}
	.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6  {
		max-width:800px;
		margin:0 auto 38px auto;
		font-size:40px;
		line-height:48px;
		@include font-family($font-semibold);
		color: $color-black;
		@include screen(custom, max, $max-md) {
			font-size:36px;
			line-height:44px;
		}
		@include screen(custom, max, $max-sm) {
			font-size:28px;
			line-height:32px;
			margin:0 auto 24px auto;
		}
	}
	.product-type {
		background-color:$bg-white;
		width:100%;
		max-height:600px;
		overflow:hidden;
		@include screen(custom, max, $max-sm) {
			margin:0;
		}
		.items {
			@extend %clearfix;
			width:100%;
			a {
				display:block;
				max-width:240px;
				margin:0 auto;
				@include screen(custom, max, $max-sm) {
					max-width:100%;
				}
			}
			.slick-list {
				width:auto;
				overflow:hidden;
				//padding:0 20px;
				//margin-right: -24px;
				@include screen(custom, max, 1444) {
					padding:0;
				}
			}
			.slick-arrow {
				padding:0;
				&.slick-prev,
				&.slick-next {
					position:absolute;
					//top:182px;
					top:50%;
					width:22px;
					height:40px;
					transform: translate(0,-50%);
					z-index:99;
				}
				&.slick-disabled {
					opacity:0.3;
				}
			}
			opacity:0;
			&.slick-initialized {
				opacity:1;
			}
		}
		.item {
			float:left;
			margin:0 10px;
			outline: none;
			@include screen(custom, max, $max-sm) {
				margin:0;
			}
			@include screen(custom, min, $min-lg) {
				&:nth-child(4n-3) {&:before {display:none;}}
			}
			@include screen(md) {
				&:nth-child(3n-2) {&:before {display:none;}}
			}
			@include screen(custom, max, $max-sm) {
				&:nth-child(2n-1) {&:before {display:none;}}
			}
			.visual {
				text-align:center;
			}
			.visual,
			.model-name {
				width: 100%;
				margin:0 auto;
			}
			.body-copy { 
				font-size:16px; 
				line-height:24px; 
				color:$color-dimgray; 
				margin:0;
				@include screen(custom, max, $max-sm) {
					margin-bottom:16px;
				}
			}
			.visual {
				// background:rgba(165,0,52,0.2);
				// img {width:100%;height:auto;}
				width: auto;
				a {
					display:block;
				}
				img {
					max-width: 180px;
				}
				margin-bottom:35px;
				@include screen(custom, max, $max-sm) {
					padding: 0 15px;
					margin-bottom:30px;
					box-sizing: border-box;
					img {
						width: auto !important;
					}
				}
			}
			.model-name {
				height: (26px*2);
				font-size: 20px;
				line-height:26px;
				display:block;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2; 
				-webkit-box-orient: vertical;
				word-wrap:break-word;
				@include screen(custom, max, $max-sm) {
					margin-top:0;
				}
				.text {
					color: $color-nightrider;
					@include font-family($font-semibold);
					font-size: 20px;
					line-height: 26px;
					&:after {
						content: '';
						display: inline-block;
						width: 12px;
						height: 12px;
						margin-left: 3px;
						background: url(/lg5-common/images/common/icons/link-right-dark.svg) no-repeat 0 1px;
					}
				}
				&.top{
					margin-bottom:19px;
					@include screen(custom, max, $max-sm) {
						margin-bottom:14px;
					}
				}
				&.bottom{
					margin-bottom:8px;

				}

			}
		}

		// slick carousel stylesheet
		.slick-slider {
			button {
				appearance: none;
				border: none;
				background-color: transparent;
				text-indent: -9999em;
				// line-height: 0;
				// outline: none;
			}
			.slick-arrow {
				line-height:0;
				svg {
					display: block;
				}
				color: $color-gray;
				&:hover,
				&:focus {
					//color: $color-nightrider;
					color: $color-black;
				}
			}
			.slick-dots {
				font-size: 0;
				text-align: center;	
				margin-top:18px;
				margin-bottom:0;
				height:12px;
				@include screen(custom, max, $max-sm) {
					margin-bottom:0;
					height:10px;
				}
				li {
					display:inline-block;
					width:12px;
					height:12px;
					position:relative;
					border-radius:50%;
					margin-left:10px;
					line-height:normal;
					border:1px solid $color-gray;
					&:first-child {
						margin-left:0;
					}
					@include screen(custom, max, $max-sm) {
						width:10px;
						height:10px;
						margin-left:8px;
					}
					a, button {
						background: $color-white;
						display:block;
						width:100%;
						height:100%;
						margin:0;
						padding:0;
						border:0;
						border-radius:50%;
						&:focus {
							outline:1px dotted;outline:5px auto -webkit-focus-ring-color;
						}
					}
					&.slick-active {
						border:1px solid $color-carmine;
						a, button {
							background:$color-carmine;
						}
					}
					&:only-child {
						display:none;
					}
				}
			}
		}
	}
	
}